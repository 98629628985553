import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from '../_services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private accountService: AccountService, private router: Router, private toastrService: ToastrService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(
                (httpEvent: any) => {
                    if (httpEvent.type === 0) {
                        return;
                    }
                    if (httpEvent instanceof HttpResponse) {
                        if (httpEvent.headers.has('Authorization')) {
                            let auth = httpEvent.headers.get('Authorization');
                            this.accountService.updateToken(auth as string);
                        }
                    }
                }
            )
        ).pipe(catchError(err => {
            if (err.status === 401) {
                //check current page ?
                let redirect = this.router.url != '/reset' && this.router.url != '/passwordreset'  && this.router.url != '/login';
                // auto logout if 401 response returned from api
                this.accountService.logout(redirect);
            }


            if (err.status == 403) {
                if (err.error.message == 'You must login again') {
                    //Log us out 
                    let redirect = this.router.url != '/reset' && this.router.url != '/passwordreset';
                    this.accountService.logout(redirect);
                }
            }

            const error = err.error.message || err.statusText;
            return throwError(error);

        }))
    }
}