import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PageObject, SearchPayload, User } from '../_models';
import { environment } from 'src/environments/environment';


@Injectable({ providedIn: 'root' })
export class AdminService {
   
    constructor(
        private router: Router,
        private http: HttpClient
    ) {
    }

    ping(){
        return this.http.get(`${environment.apiUrl}/admin/ping`);
    }

    create(user: User) {
        return this.http.post(`${environment.apiUrl}/admin`, user);
    }

    getAll() {
        return this.http.get<any>(`${environment.apiUrl}/admin`);
    }
    search(payload) {
        return this.http.post<any>(`${environment.apiUrl}/admin/search`, payload);
    }

    getById(id: string) {
        return this.http.get<User>(`${environment.apiUrl}/admin/${id}`);
    }

    update(id: number, params: any) {
        params.userId = id;
        return this.http.post(`${environment.apiUrl}/admin`, params)
            .pipe(map(x => {
                return x;
            }));
    }

    delete(id: string) {
        return this.http.delete(`${environment.apiUrl}/admin/${id}`);
    }
}