import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../_models';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AccountService {
    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;
    public USER_KEY = 'uploader_key';

    public headerDict:any = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      };

    public requestOptions:any = null;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.userSubject = new BehaviorSubject<any>(localStorage.getItem(this.USER_KEY));
        this.user = this.userSubject.asObservable();

        
    }

    public get userValue(): User {
        return this.userSubject.value;
    }

    login(email: string, password: string) {


        this.requestOptions = {                                                                                                                                                                                 
            headers: this.headerDict, 
          };


        return this.http.post<User>(`${environment.apiUrl}/login`, { email, password }, this.requestOptions)
            .pipe(map((user: any) => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem(this.USER_KEY, user.access_token);

                this.userSubject.next(user.access_token);
                return user;
            }));
    }

    requestPassword(email) {
        return this.http.post<User>(`${environment.apiUrl}/request_password`, { email })
            .pipe(map(data => {
                return data;
            }));
    }

    passwordReset(email, resetCode, password, repassword) {
        return this.http.post<User>(`${environment.apiUrl}/reset_password`, { email, resetCode, password, repassword })
            .pipe(map(data => {
                return data;
            }));
    }

    changePassword(oldpassword, password, repassword) {
        return this.http.post<User>(`${environment.apiUrl}/update_password`, { oldpassword, password, repassword });
    }

    logout(redirect?: boolean) {
        // remove user from local storage and set current user to null
        localStorage.removeItem(this.USER_KEY);
        this.userSubject.next(null);

        if (redirect == false) {
            return;
        }
        this.router.navigate(['/login']);
    }



    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/user`);
    }

    getById(id: string) {
        return this.http.get<User>(`${environment.apiUrl}/user/${id}`);
    }



    delete(id: string) {
        return this.http.delete(`${environment.apiUrl}/users/${id}`)
            .pipe(map(x => {
                // auto logout if the logged in user deleted their own record
                if (id == this.userValue.id) {
                    this.logout();
                }
                return x;
            }));
    }

    refreshUser() {
        return this.http.get<any>(`${environment.apiUrl}/refresh`)
            .pipe(map((user: any) => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem(this.USER_KEY, user.access_token);
                this.userSubject.next(user.access_token);
                return user;
            }));
    }
    /**
     * Update the access token
     * @param token
     */
    updateToken(token: string) {
        localStorage.setItem(this.USER_KEY, token);
    }
}