import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';


const adminModule = () => import('./admin/admin.module').then(x => x.AdminModule);
const accountModule = () => import('./account/account.module').then(x => x.AccountModule);
const imagesModule = () => import('./images/images.module').then(x => x.ImagesModule);

const routes: Routes = [
  { path: '', loadChildren: accountModule, canActivate: [AuthGuard] },
  { path: 'images', loadChildren: imagesModule, canActivate: [AuthGuard] },
  { path: 'admin', loadChildren: adminModule, canActivate: [AuthGuard] },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
