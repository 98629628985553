import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AccountService } from '../_services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {



  adminRoutes = [
    '/admin'
  ];

  
  constructor(
    private router: Router,
    private accountService: AccountService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

     if(undefined == this.accountService.userValue || this.accountService.userValue == null){
      if(state.url != '/' && !state.url.startsWith('/reset') && !state.url.startsWith('/login') && !state.url.startsWith('/passwordreset')){     
        this.router.navigate(['/login']);
        return false;
      }
    }else{

  
      if(state.url == '/' || state.url.startsWith('/reset') || state.url.startsWith('/passwordreset') || state.url.startsWith('/login')){
        this.router.navigate(['/images']);
        return false;
      }
    }

    


    return true;
  }

}
