import { Component, OnInit } from '@angular/core';
import { AdminService } from './_services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'uploader';



  constructor(
    private adminService: AdminService,
  ) { }

  ngOnInit() {
    this.adminService.ping().subscribe();
  }
}
